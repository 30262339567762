//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { modelAdd } from '@/api/modular/flowable/modelManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    add () {
      this.visible = true
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          modelAdd(values).then((res) => {
            if (res.success) {
              this.$message.success('新增成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}
